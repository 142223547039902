
import Vue from "vue";
import RegistrationService from "@/services/RegistrationService";
import {RegistrationsViewModel} from "@/models/RegistrationsViewModel";
import {makeToast, ToastVariant} from "@/utils/toast";
import {ADD_DEVICE_MESSAGES} from "@/constants/messages";
import {showMessageBox} from "@/utils/messageBox";
import {TERMINATE_REGISTRATION_MESSAGES} from "@/constants/messages";
import TerminateRegistrationService from "@/services/TerminateRegistrationService";
import {TerminationReasonsOption} from "@/models/TerminationReasonsOption";
import TerminationReasonService from "@/services/TerminationReasonService";
import {TerminationReasonValue} from "@/models/TerminationReasonValue";
import { PAGINATION_PROPERTIES } from "@/constants/PaginationProperties";

export default Vue.extend({
  props: {
    name: String,
    id: String,
  },
  data() {
    return {
      items: new Array<RegistrationsViewModel>(),
      fields: [
        {key: "last_name", label: "Last Name", sortable: true},
        {key: "room_number", label: "Room Number", sortable: true},
        {key: "plan_name", label: "Plan Name", sortable: true},
        {
          key: "expiration_date",
          label: "Expiration Date",
          sortable: true,
          formatter: (value: never) => {
            const date = new Date(value);
            return date.toLocaleString("en-US");
          },
        },
        {
          key: "connected_devices",
          label: "Connected Devices",
          sortable: true,
          formatter: (value: never) => {
            let formatter = new Intl.NumberFormat("en-US");
            return formatter.format(value);
          },
        },
        {
          key: "allowed_devices",
          label: "Allowed Devices",
          sortable: true,
          formatter: (value: never) => {
            let formatter = new Intl.NumberFormat("en-US");
            return formatter.format(value);
          },
        },
        {
          key: "bandwidth_upload",
          label: "Bandwidth Up",
          sortable: true,
          formatter: (value: never) => {
            let formatter = new Intl.NumberFormat("en-US");
            return formatter.format(value);
          },
        },
        {
          key: "bandwidth_download",
          label: "Bandwidth Down",
          sortable: true,
          formatter: (value: never) => {
            let formatter = new Intl.NumberFormat("en-US");
            return formatter.format(value);
          },
        },
        {
          key: "price",
          label: "Price",
          sortable: true,
          formatter: (value: never) => {
            let formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });
            return formatter.format(value);
          },
        },
        {key: "actions", label: "Actions", sortable: false},
        {
          key: "actions", label: "Actions", sortable: false
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: PAGINATION_PROPERTIES.PER_PAGE,
      pageOptions: PAGINATION_PROPERTIES.PAGE_OPTIONS,
      sortBy: "last_name",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      isTableLoading: true,
      userToUpdate: null as RegistrationsViewModel | null,
      numberOfDevices: "1",
      macAddresses: [] as string[],
      selectedTerminationReason: null as TerminationReasonValue | null,
      terminationReasons: new Array<TerminationReasonsOption>(),
      registrationToTerminate: null as RegistrationsViewModel | null,
      terminateModalTitle: TERMINATE_REGISTRATION_MESSAGES.CONFIRMATION_MODAL_TITLE,
    };
  },
  async mounted() {
    await this.fetchRegistrations();
    await this.fetchTerminationReasons();
  },
  methods: {
    isValidDeviceCount(count: number): boolean {
      if (this.userToUpdate) {
        return count > 0 && count <= this.remainingDevices();
      } else {
        return false;
      }
    },
    validateDeviceNumInput(evt: KeyboardEvent): void {
      const inputNumber = parseInt(`${this.numberOfDevices}` + evt.key);
      if (!this.isValidDeviceCount(inputNumber)) {
        evt.preventDefault();
      }
    },
    remainingDevices(): number {
      if (this.userToUpdate) {
        return (
            this.userToUpdate?.allowed_devices -
            this.userToUpdate?.connected_devices
        );
      }
      return 0;
    },
    incrementDeviceCount() {
      const incrementedCount = parseInt(this.numberOfDevices) + 1;
      if (this.isValidDeviceCount(incrementedCount)) {
        this.numberOfDevices = incrementedCount.toString();
      }
    },
    decrementDeviceCount() {
      const incrementedCount = parseInt(this.numberOfDevices) - 1;
      if (this.isValidDeviceCount(incrementedCount)) {
        this.numberOfDevices = incrementedCount.toString();
      }
    },
    onFiltered(filteredItems: RegistrationsViewModel[]) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(value: Date) {
      const date = new Date(value);
      return date.toLocaleString("en-US");
    },
    async fetchRegistrations() {
      try {
        this.items = await RegistrationService.getRegistrationsForHotel({
          site_id: this.id,
        });
        this.totalRows = this.items.length;
      } catch (error) {
        console.warn(error);
      } finally {
        this.isTableLoading = false;
      }
    },
    hasDuplicates(): boolean {
      // checks for duplicate mac addresses
      let strippedAddresses: string[] = [];
      this.macAddresses.forEach((macAddress, index) => {
        strippedAddresses[index] = macAddress.replaceAll(":", "");
      });
      const isDistinctLength =
          new Set(strippedAddresses).size !== strippedAddresses.length;
      if (isDistinctLength) {
        showMessageBox(
            ADD_DEVICE_MESSAGES.DUPLICATE_MAC_ADDRESS_ERROR_TITLE,
            ADD_DEVICE_MESSAGES.DUPLICATE_MAC_ADDRESS_ERROR_BODY
        );
      }
      return isDistinctLength;
    },
    async addDevices(userDevices: any, hide: any) {
      try {
        const result = await RegistrationService.addDevices(userDevices);
        if (result.status === "OK") {
          makeToast(
            ToastVariant.success,
            ADD_DEVICE_MESSAGES.SUCCESS_TOAST_TITLE,
            ADD_DEVICE_MESSAGES.SUCCESS_TOAST_BODY
          );
          this.macAddresses = [];
          this.userToUpdate = null;
          this.numberOfDevices = "1";
          hide();
        }
        else if (result.status === "ERROR") {
          console.log(result);
          makeToast(
              ToastVariant.danger,
              ADD_DEVICE_MESSAGES.ERROR_TOAST_TITLE,
              result.status_description,
              false
          );
        }
      } catch (error: any) {
        console.warn(error);
        const error_response = error.response.data;
        var errText = "";
        if (error_response?.description) {
          errText = error_response.description;
        } else {
          errText = ADD_DEVICE_MESSAGES.ERROR_TOAST_BODY;
          console.log(`error is ", ${error}`);
        }

        makeToast(
            ToastVariant.danger,
            ADD_DEVICE_MESSAGES.ERROR_TOAST_TITLE,
            errText,
            false
        );
      } finally {
        this.isTableLoading = true;
        await this.fetchRegistrations();
        this.isTableLoading = false;
      }
    },
    validateMacAddresses() {
      let isValid = true;
      this.macAddresses.forEach((macAddr, index) => {
        if (!this.isValidMacAddress(macAddr)) {
          (
              this.$refs["mac-input-error" + index] as HTMLElement[]
          )[0]?.classList.add("d-block");
          isValid = false;
        }
      });
      return isValid;
    },
    filterRegistrations(item: RegistrationsViewModel, searchString: string) {
      const values = Object.values((({expiration_date, ...o}) => o)(item));
      const searchStringLowerCased = searchString.toLocaleLowerCase();
      if (
          values.toString().toLocaleLowerCase().includes(searchStringLowerCased)
      ) {
        return true;
      } else {
        const date = this.formatDate(item.expiration_date).toLocaleLowerCase();
        return date.includes(searchStringLowerCased);
      }
    },
    refreshRegistrationTable: async function () {
      await this.fetchRegistrations();
    },
    isFormValid(deviceCount: number) {
      return (
          !this.macAddresses.includes("") &&
          this.macAddresses.length >= deviceCount
      );
    },
    submit(hide: any) {
      const numOfDevices = parseInt(this.numberOfDevices);
      if (
          this.isFormValid(numOfDevices) &&
          this.validateMacAddresses() &&
          !this.hasDuplicates()
      ) {
        const addDeviceRequest = {
          allowed_devices: this.userToUpdate?.allowed_devices,
          confirm_num: this.userToUpdate?.confirm_num,
          email: this.userToUpdate?.email,
          first_name: this.userToUpdate?.first_name,
          last_name: this.userToUpdate?.last_name,
          mac_address: this.macAddresses.slice(0, numOfDevices),
          plan_id: this.userToUpdate?.plan_id,
          plan_expiration_date: this.userToUpdate?.expiration_date,
          room_number: this.userToUpdate?.room_number.toString(),
          site_id: this.id,
        };
        this.addDevices(addDeviceRequest, hide);
      }
    },
    async fetchTerminationReasons() {
      try {
        this.terminationReasons = await TerminationReasonService.getTerminationReasons();
        this.terminationReasons = [
          {
            value: null,
            text: TERMINATE_REGISTRATION_MESSAGES.TERMINATION_REASON_DD_TITLE
          },
          ...this.terminationReasons];
      } catch (error) {
        console.warn(error);
      }
    },
    confirmationModalBody: TERMINATE_REGISTRATION_MESSAGES.CONFIRMATION_MODAL_BODY,
    async terminateRegistration(hide: any) {
      try {
        if (this.registrationToTerminate && this.selectedTerminationReason) {
          await TerminateRegistrationService.terminateRegistration({
            registration_id: this.registrationToTerminate.registration_id,
            termination_reason_id: this.selectedTerminationReason.termination_reason_id
          });
          makeToast(
              ToastVariant.success,
              TERMINATE_REGISTRATION_MESSAGES.SUCCESS_TOAST_TITLE,
              TERMINATE_REGISTRATION_MESSAGES.SUCCESS_TOAST_BODY(
                  this.registrationToTerminate?.last_name,
                  this.registrationToTerminate?.room_number,
              )
          );
        }
      } catch (err) {
        makeToast(
            ToastVariant.danger,
            TERMINATE_REGISTRATION_MESSAGES.ERROR_TOAST_TITLE,
            TERMINATE_REGISTRATION_MESSAGES.ERROR_TOAST_BODY(
                `${this.registrationToTerminate?.last_name}`,
                this.registrationToTerminate?.room_number ?? -1,
            ),
            false
        );
        console.warn(err);
      } finally {
        await this.fetchRegistrations();
        hide();
      }
    },
    isValidMacAddress(macAddress: string): boolean {
      const macRegex = /^((([0-9A-F]{2}:){5})|([0-9A-F]{10}))([0-9A-F]{2})$/;
      return macRegex.test(macAddress);
    },
    validateInput(macAddress: string, index: number) {
      if (this.isValidMacAddress(macAddress)) {
        (
            this.$refs["mac-input-error" + index] as HTMLElement[]
        )[0]?.classList.remove("d-block");
      }
    },
  },
});
